import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

interface TextInputFieldProps {
  label?: string;
  className?: string;
  name: string;
  onChange: (e: any) => void;
  type?: string;
  onBlur?: any;
  error?: any;
  helperText?: JSX.Element;
  value?: string;
  shrinkLabel?: boolean;
  textFieldClassName?: string;
  role?: React.AriaRole;
  InputProps?: {
    endAdornment: JSX.Element;
  };
  variant?: 'standard' | 'filled' | 'outlined';
}

const SearchBar: React.FC<TextInputFieldProps> = React.forwardRef(
  (
    {
      label,
      className,
      name,
      onChange,
      onBlur,
      error,
      value,
      shrinkLabel,
      role,
      InputProps,
      variant = 'outlined',
    }: TextInputFieldProps,
    ref
  ) => {
    const labelSlug = label ? label.replace(' ', '-') : undefined;
    const roleProp: { role?: React.AriaRole } = {};

    const InputLabelProps = { shrink: shrinkLabel };

    if (role) roleProp.role = role;
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          fullWidth
          variant={variant}
          id={`${labelSlug}__htmlFor`}
          label={label}
          name={name}
          onChange={onChange}
          type="text"
          inputRef={ref}
          onBlur={onBlur}
          error={error}
          value={value}
          InputLabelProps={InputLabelProps}
          className={className}
          InputProps={InputProps}
          {...roleProp}
        />
      </Box>
    );
  }
);

export default SearchBar;
