// import { GridColDef } from '@mui/x-data-grid';
import * as d3 from 'd3';
import React, { RefObject, useState, useEffect } from 'react';
import { Card, CircularProgress } from '@mui/material';

import DashboardWidgetProps from './types/DashboardWidget';

export interface ZeroOutRateData {
  call_date: string;
  ct: number;
}

export const ZeroOutRateComponent: React.FunctionComponent<
  DashboardWidgetProps
> = ({ daysDiff, endDate, className }) => {
  // const svg:any;
  const margin = 50;
  const svgRef: RefObject<SVGSVGElement> = React.useRef<SVGSVGElement>(null);
  const [fetching, setFetching] = useState(false);

  // type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  // ? Acc[number]
  // : Enumerate<N, [...Acc, Acc['length']]>

  // type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

  // type T = IntRange<1, 7>

  useEffect(() => {
    const svgContainer = d3.select('#zerooutratediv');
    const width =
      (svgContainer!.node()! as Element).getBoundingClientRect().width -
      margin * 2;
    const height = 400 - margin * 2;
    const drawBars: (
      svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      data: ZeroOutRateData[]
    ) => void = (
      svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      data: ZeroOutRateData[]
    ) => {
      const x = d3
        .scaleBand()
        .range([0, width])
        .domain(data.map((d) => d.call_date))
        .padding(0.2);

      const xAxis = d3.axisBottom(x).tickFormat((d) => {
        return d.split('T')[0];
      });

      const maxCt = Math.max(...data.map((val) => val.ct));
      const y = d3.scaleLinear().domain([0, maxCt]).range([height, 0]);

      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(xAxis)
        .selectAll('text')
        .attr('transform', 'translate(-10,0)rotate(-45)')
        .style('text-anchor', 'end');

      svg.append('g').call(d3.axisLeft(y));

      // svg.append("g")
      // .call(d3.axisLeft(y).tickFormat(d => (keyMap[parseInt()])))

      //     // Create and fill the bars
      svg
        .selectAll('bars')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', (d: ZeroOutRateData) =>
          x(d.call_date) !== undefined ? x(d.call_date)! : null
        )
        .attr('y', (d: ZeroOutRateData) => y(d.ct))
        .attr('height', (d: ZeroOutRateData) => height - y(d.ct))
        .attr('width', x.bandwidth())
        .attr('fill', '#62a0a5');
    };

    const createSvg = (data: ZeroOutRateData[]) => {
      // d3.select("#zerooutratediv").selectAll('*').remove()

      const svg = d3
        .select('#zerooutratediv')
        .append('svg')
        .attr('id', 'zerooutratesvg')
        .attr('width', width + margin * 2)
        .attr('height', height + margin * 2)
        .append('g')
        .attr('transform', `translate(${margin},${margin})`);
      drawBars(svg, data);
    };

    if (daysDiff) {
      d3.select('#zerooutratediv').selectAll('svg')?.remove();
      setFetching(true);
      fetch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/zerooutcalls/${daysDiff}?end_dt=${endDate}`
      )
        .then((response) => response.json())
        .then((data) => {
          setFetching(false);
          createSvg(data);
        })
        .catch((e) => console.log(e));
    }
  }, [daysDiff]);
  return (
    <Card
      title="Zero-Out Rate"
      className={className && className}
      variant="outlined"
    >
      <div>
        <div style={{ display: 'grid', alignItems: 'normal' }}>
          <div>Zero-Out Rate</div>
        </div>
      </div>
      <div id="zerooutratediv">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        <svg ref={(ref: SVGSVGElement) => svgRef.current} />
        {fetching && (
          <div>
            <CircularProgress style={{ color: '#62a0a5' }} />
          </div>
        )}
      </div>
    </Card>
  );
};
