import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Navigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { LoggedInContext } from '../context/LoggedIn';
import './Login.css';

const Login: React.FC = () => {
  const UserContext = useContext(LoggedInContext);

  if (UserContext?.loggedIn === true) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="Login">
      <div className="Login__image">
        <img src="assets/Insights_landingGraphic-01.png" alt="" />
      </div>
      <div className="Login__auth-container">
        <div className="authenticator">
          <div className="authenticator__container">
            <div className="authenticator__form">
              <div className="authenticator__form-title">AIECS Insights</div>
              <div className="authenticator__form-subtitle">
                Welcome back! Please login to your account.
              </div>
              <div className="amplify-flex federated-sign-in-container">
                <button
                  type="button"
                  className="amplify-button amplify-field-group__control federated-sign-in-button"
                  style={{
                    fontWeight: 'var(--amplify-font-weights-normal)',
                    gap: '1rem',
                    width: '100%',
                  }}
                  onClick={() =>
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    })
                  }
                >
                  {UserContext?.loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <span className="amplify-text">Sign In with Google</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="Login__1904-img">
          <img src="assets/1904labsFull_lightBG.png" alt="1904labs logo" />
        </div>
      </div>
    </div>
  );
};

export default Login;
