import React, { RefObject, useState, useEffect } from 'react';
// import { GridColDef } from '@mui/x-data-grid';
import * as d3 from 'd3';
import { Card, CircularProgress } from '@mui/material';

import DashboardWidgetProps from './types/DashboardWidget';
import { groupBy } from './utils';

export const ConversationDurationComponent: React.FC<DashboardWidgetProps> = ({
  daysDiff,
  endDate,
  className,
}) => {
  const margin = 50;
  const svgRef: RefObject<SVGSVGElement> = React.useRef<SVGSVGElement>(null);
  const [fetching, setFetching] = useState(false);

  // type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  // ? Acc[number]
  // : Enumerate<N, [...Acc, Acc['length']]>

  // type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>

  // type T = IntRange<1, 7>

  useEffect(() => {
    type KeyMapType = { [key: number]: string };
    const keyMap: KeyMapType = {
      1: '< 30',
      2: '30-60',
      3: '60-90',
      4: '90-120',
      5: '120-150',
      6: '150-180',
      7: '>180',
    };
    const svgContainer = d3.select('#calldurationdiv');
    const width =
      (svgContainer!.node()! as Element).getBoundingClientRect().width -
      margin * 2;
    const height = 400 - margin * 2;
    type DurationData = {
      key: number;
      value: number;
    };
    const drawBars: (
      svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      data: DurationData[]
    ) => void = (
      svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
      data: DurationData[]
    ) => {
      const y = d3
        .scaleBand()
        .range([height, 1])
        .domain(Object.keys(keyMap))
        .padding(0.2);

      const x = d3
        .scaleLinear()
        .domain([Math.max(...data.map((d) => d.value)), 0])
        .range([width, 0]);

      svg
        .append('g')
        .attr('transform', `translate(0, ${height})`)
        .style('text-anchor', 'end')
        .call(d3.axisBottom(x));

      svg
        .append('g')
        .call(d3.axisLeft(y).tickFormat((d) => keyMap[parseInt(d, 10)]));

      //     // Create and fill the bars
      svg
        .selectAll('bars')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', 0)
        .attr('y', (d: DurationData) =>
          y(d.key.toString()) !== undefined ? y(d.key.toString())! : null
        )
        .attr('height', y.bandwidth())
        .attr('width', (d: DurationData) => x(d.value ? d.value : 0))
        .attr('fill', '#62a0a5');
    };

    const createSvg = (data: DurationData[]) => {
      d3.select('#calldurationdiv').selectAll('svg')?.remove();

      const svg = d3
        .select('#calldurationdiv')
        .append('svg')
        .attr('id', 'calldurationsvg')
        .attr('width', width + margin * 2)
        .attr('height', height + margin * 2)
        .append('g')
        .attr('transform', `translate(${margin}, ${margin})`);
      drawBars(svg, data);
    };
    type BucketType = { [key: number]: [value: number, bucket: number] };

    if (daysDiff) {
      // axios.get(`${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/callduration/${daysDiff}`,{timeout:120000})
      // axios({url: `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/callduration/${daysDiff}`, timeout: 120000})
      d3.select('#calldurationdiv').selectAll('svg')?.remove();
      setFetching(true);
      fetch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/callduration/${daysDiff}?end_dt=${endDate}`
      )
        .then((response) => response.json())
        .then((data) => {
          setFetching(false);
          const totalCalls = data.length;
          const groupedByBucket: BucketType = groupBy(data, 'bucket');
          const dataSummaryTemp = Object.keys(groupedByBucket).map((key) => ({
            key: parseInt(key, 10),
            value:
              ((groupedByBucket[parseInt(key, 10)].length * 1.0) / totalCalls) *
              100,
          }));
          // setDataSummary(dataSummaryTemp)
          createSvg(dataSummaryTemp);
        })
        .catch((e) => console.log(e));
    }
  }, [daysDiff]);
  return (
    <Card
      title="Conversations Duration"
      className={className && className}
      variant="outlined"
    >
      <div>
        <div style={{ display: 'grid', alignItems: 'normal' }}>
          <div>Conversations Duration</div>
        </div>
      </div>
      <div id="calldurationdiv">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        <svg ref={(ref: SVGSVGElement) => svgRef.current} />
        {fetching && (
          <div>
            <CircularProgress style={{ color: '#62a0a5' }} />
          </div>
        )}
      </div>
    </Card>
  );
};
