import { CallSummary } from '../customtypes';

type FilterCondition = (a: string, b: string) => boolean;

const createFilter =
  (condition: FilterCondition, filterMethod: 'some' | 'every' = 'some') =>
  (column: string, value: string, nested: boolean, calls: CallSummary[]) => {
    const lowerCaseInputtedValue = value.toLowerCase();

    return calls.filter((call) => {
      const meetsCondition = (item: { [key: string]: any }) => {
        const itemValue = item[column];
        return (
          itemValue &&
          condition(itemValue.toString().toLowerCase(), lowerCaseInputtedValue)
        );
      };

      return nested
        ? call.history[filterMethod](meetsCondition)
        : meetsCondition(call);
    });
  };

// Specify 'every' as the filter method for doesNotContainFilter and doesNotEqualFilter
export const containsFilter = createFilter((a, b) => a.includes(b), 'some');
export const equalsFilter = createFilter((a, b) => a === b, 'some');
export const doesNotContainFilter = createFilter(
  (a, b) => !a.includes(b),
  'every'
);
export const doesNotEqualFilter = createFilter((a, b) => a !== b, 'every');

// 1ST ATTEMPT, KEEPING FOR REFERENCE. THESE PASS THE TESTS BUT ARE NOT DRY

// export const containsFilter = (
//   column: string,
//   value: string,
//   nested: boolean,
//   calls: CallSummary[]
// ) => {
//   if (!nested) {
//     const filteredCalls = calls.filter((call) => {
//       // @ts-ignore
//       if (call[column]) {
//         // @ts-ignore
//         return call[column]
//           .toString()
//           .toLowerCase()
//           .includes(value.toLowerCase());
//       }
//       return false;
//     });
//     return filteredCalls;
//   }

//   // if nested is true then we need to go into calls.history which is an array of objects and filter through those for the column's value
//   const filteredCalls = calls.filter((call) =>
//     // @ts-ignore
//     call.history.some((history) => {
//       // @ts-ignore
//       if (history[column]) {
//         // @ts-ignore
//         return history[column]
//           .toString()
//           .toLowerCase()
//           .includes(value.toLowerCase());
//       }

//       return false;
//     })
//   );
//   return filteredCalls;
// };

// export const equalsFilter = (
//   column: string,
//   value: string,
//   nested: boolean,
//   calls: CallSummary[]
// ) => {
//   if (!nested) {
//     // filter filteredCalls to only contain calls where the column's value equals the value exactly
//     const filteredCalls = calls.filter((call) => {
//       // @ts-ignore
//       if (!call[column]) {
//         return false;
//       }
//       // @ts-ignore
//       return call[column].toString().toLowerCase() === value.toLowerCase();
//     });

//     return filteredCalls;
//   }

//   // if nested is true then we need to go into calls.history which is an array of objects and filter through those for the column's value
//   const filteredCalls = calls.filter((call) =>
//     // @ts-ignore
//     call.history.some((history) => {
//       // @ts-ignore
//       if (!history[column]) {
//         return false;
//       }

//       // @ts-ignore
//       return history[column].toString().toLowerCase() === value.toLowerCase();
//     })
//   );
//   return filteredCalls;
// };

// export const doesNotContainFilter = (
//   column: string,
//   value: string,
//   nested: boolean,
//   calls: CallSummary[]
// ) => {
//   if (!nested) {
//     const filteredCalls = calls.filter((call) => {
//       // @ts-ignore
//       if (!call[column]) {
//         return true;
//       }

//       // @ts-ignore
//       return !call[column]
//         .toString()
//         .toLowerCase()
//         .includes(value.toLowerCase());
//     });
//     return filteredCalls;
//   }

//   // if nested is true then we need to go into calls.history which is an array of objects and filter through those for the column's value that does not contain the value
//   const filteredCalls = calls.filter((call) =>
//     // @ts-ignore
//     call.history.every((history) => {
//       // @ts-ignore
//       if (!history[column]) {
//         return true;
//       }

//       // @ts-ignore
//       return !history[column]
//         .toString()
//         .toLowerCase()
//         .includes(value.toLowerCase());
//     })
//   );
//   return filteredCalls;
// };

// export const doesNotEqualFilter = (
//   column: string,
//   value: string,
//   nested: boolean,
//   calls: CallSummary[]
// ) => {
//   if (!nested) {
//     const filteredCalls = calls.filter((call) => {
//       // @ts-ignore
//       if (!call[column]) {
//         return true;
//       }

//       // @ts-ignore
//       return call[column].toString().toLowerCase() !== value.toLowerCase();
//     });
//     return filteredCalls;
//   }

//   // if nested is true then we need to go into calls.history which is an array of objects and filter through those for the column's value that does not equal the value
//   const filteredCalls = calls.filter((call) =>
//     // @ts-ignore
//     call.history.every((history) => {
//       // @ts-ignore
//       if (!history[column]) {
//         return true;
//       }

//       // @ts-ignore
//       return history[column].toString().toLowerCase() !== value.toLowerCase();
//     })
//   );
//   return filteredCalls;
// };
