import React, { RefObject, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import * as d3 from 'd3';

import { CallCountData } from './customtypes';

type CallCountComponentProps = {
  callCountData: CallCountData[];
};

export const CallCountComponent: React.FunctionComponent<
  CallCountComponentProps
> = (props) => {
  const { callCountData } = props;
  // const svg:any;
  const margin = 50;
  const width = 750 - margin * 2;
  const height = 400 - margin * 2;
  const svgRef: RefObject<SVGSVGElement> = React.useRef<SVGSVGElement>(null);

  useEffect(() => {
    createSvg();
  }, []);

  const createSvg = () => {
    d3.select('#callcountdiv').selectAll('*').remove();
    //     // const svgElement: SvgInHtml = document.createElement('svg') as SvgInHtml;

    const svg = d3
      .select('#callcountdiv')
      .append('svg')
      .attr('id', 'callcountsvg')
      .attr('width', width + margin * 2)
      .attr('height', height + margin * 2)
      .append('g')
      .attr('transform', `translate(${margin}, ${margin})`);
    drawBars(svg, callCountData);
  };

  const drawBars: (
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    callCountData: CallCountData[]
  ) => void = (
    svg: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
    callCountData: CallCountData[]
  ) => {
    // Create the X-axis band scale
    // console.log(this.svg)
    const data: CallCountData[] = callCountData;

    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(data.map((d) => d.start_dt))
      .padding(0.2);

    const xAxis = d3.axisBottom(x).tickFormat((d) => {
      return d.split('T')[0];
    });

    //     // Draw the X-axis on the DOM
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis)
      .selectAll('text')
      .attr('transform', 'translate(-10,0)rotate(-45)')
      .style('text-anchor', 'end');

    // Create the Y-axis band scale
    const maxCt = Math.max(...data.map((d) => d.ct));
    const y = d3.scaleLinear().domain([0, maxCt]).range([height, 0]);

    //     // Draw the Y-axis on the DOM
    svg.append('g').call(d3.axisLeft(y));

    //     // Create and fill the bars
    svg
      .selectAll('bars')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d: CallCountData) => x(d.start_dt)!)
      .attr('y', (d: CallCountData) => y(d.ct))
      .attr('width', x.bandwidth())
      .attr('height', (d: CallCountData) => height - y(d.ct))
      .attr('fill', '#62a0a5');
  };

  // drawBars(callCountData)
  const columns: GridColDef[] = [
    { field: 'start_dt', headerName: 'Call Date', width: 150 },
    { field: 'ct', headerName: 'Count', width: 130 },
  ];

  return (
    <>
      <div id="callcountdiv">
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        <svg ref={(ref: SVGSVGElement) => svgRef.current} />
      </div>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={callCountData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          getRowId={(data) => data.start_dt}
        />
      </div>
    </>
  );
};
