import React from 'react';
import { Card, CircularProgress } from '@mui/material';
import MiniChart from 'react-mini-chart';

import './MiniChartWidget.css';

const MiniChartWidget: React.FC<{
  title: string;
  loading: boolean;
  clickHandler: () => void;
  count: number;
  dataSet: number[];
}> = ({ title, loading, clickHandler, count, dataSet }) => {
  return (
    <Card title={title} variant="outlined" className="MiniChartWidget">
      <div className="MiniChartWidget__container">
        <div className="MiniChartWidget__grid">
          <div>{title}</div>
          {!loading ? (
            <>
              <div
                onClick={() => clickHandler()}
                role="button"
                tabIndex={0}
                onKeyDown={() => clickHandler()}
                className="MiniChartWidget__mini-chart-container"
              >
                <MiniChart strokeColor="#62a0a5" dataSet={dataSet} />
              </div>
              <div>
                <strong>{count}</strong>
              </div>
            </>
          ) : (
            <div>
              <CircularProgress className="MiniChartWidget__progress-bar" />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default MiniChartWidget;
