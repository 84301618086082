/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';

import SearchBar from './SearchBar';
import {
  containsFilter,
  equalsFilter,
  doesNotContainFilter,
  doesNotEqualFilter,
} from '../utils/filterFunctions';
import { CallSummary } from '../customtypes';

type FilterColumn = (typeof FILTER_COLUMNS)[number];

type FilterFormType = {
  columns: FilterColumn;
  operator: string;
  value: string;
};

const FILTER_COLUMNS = ['Contact Number', 'Intent', 'Contact ID', 'Utterance'];
const OPERATORS = ['Contains', 'Equals', 'Does Not Contain', 'Does Not Equal'];

const FilterDialog: React.FC<{
  calls: CallSummary[];
  filteredCalls: CallSummary[];
  setFilteredCalls: React.Dispatch<React.SetStateAction<CallSummary[]>>;
  dialogRef: React.RefObject<HTMLDialogElement>;
}> = ({ calls, filteredCalls, setFilteredCalls, dialogRef }) => {
  const { watch, register, reset } = useForm<FilterFormType>({
    defaultValues: {
      columns: FILTER_COLUMNS[0],
      operator: OPERATORS[0],
      value: '',
    },
  });

  useEffect(() => {
    if (
      watch('columns') !== '' &&
      watch('operator') !== '' &&
      watch('value') !== ''
    ) {
      // @ts-ignore
      getFilteredCalls(watch('columns'), watch('operator'), watch('value'));
    } else if (filteredCalls.length !== calls.length) {
      setFilteredCalls(calls);
    }
  }, [watch('columns'), watch('operator'), watch('value')]);

  const getFilteredCalls = (
    column: 'Contact Number' | 'Intent',
    operator: string,
    value: string
  ) => {
    const columnLookup = {
      'Contact Number': { name: 'address', nested: false },
      'Contact ID': { name: 'contact_id', nested: false },
      Intent: { name: 'primaryintent', nested: true },
      Utterance: { name: 'utterance', nested: true },
    };

    const columnInfo = columnLookup[column];

    switch (operator) {
      case 'Contains':
        const filteredCalls = containsFilter(
          columnInfo.name,
          value,
          columnInfo.nested,
          calls
        );
        setFilteredCalls(filteredCalls);
        break;
      case 'Equals':
        const equalFilteredCalls = equalsFilter(
          columnInfo.name,
          value,
          columnInfo.nested,
          calls
        );
        setFilteredCalls(equalFilteredCalls);
        break;
      case 'Does Not Contain':
        const doesNotContainFilteredCalls = doesNotContainFilter(
          columnInfo.name,
          value,
          columnInfo.nested,
          calls
        );
        setFilteredCalls(doesNotContainFilteredCalls);
        break;
      default:
        const doesNotEqualFilteredCalls = doesNotEqualFilter(
          columnInfo.name,
          value,
          columnInfo.nested,
          calls
        );
        setFilteredCalls(doesNotEqualFilteredCalls);
    }
  };

  return (
    <dialog
      ref={dialogRef}
      className="callTranscription__dialog"
      aria-label="filter-dialog"
    >
      <div className="callTranscription__dialog-container">
        <div className="callTranscription__dialog-exit">
          <CloseIcon
            role="button"
            onClick={() => {
              dialogRef.current?.close();
              reset({
                columns: FILTER_COLUMNS[0],
                operator: OPERATORS[0],
                value: '',
              });
            }}
            aria-label="close"
            data-testid="close-filter-dialog"
          />
        </div>
        <div className="callTranscription__dialog-inputs">
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="columns">Columns</InputLabel>
            <Select
              labelId="columns"
              id="columns-select"
              {...register('columns')}
              label="Columns"
              defaultValue={FILTER_COLUMNS[0]}
            >
              {FILTER_COLUMNS.map((column) => (
                <MenuItem
                  value={column}
                  selected={column === FILTER_COLUMNS[0]}
                  key={column}
                >
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <InputLabel id="operator">Operator</InputLabel>
            <Select
              labelId="operator"
              id="operator-select"
              {...register('operator')}
              label="Operator"
            >
              {OPERATORS.map((operator) => (
                <MenuItem
                  value={operator}
                  selected={operator === OPERATORS[0]}
                  key={operator}
                >
                  {operator}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" sx={{ minWidth: 120 }}>
            <SearchBar
              {...register('value', {
                setValueAs: (value: string) => value.trim(),
              })}
              label="Value"
              variant="standard"
            />
          </FormControl>
        </div>
      </div>
    </dialog>
  );
};

export default FilterDialog;
