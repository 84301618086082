import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Close as CloseIcon } from '@mui/icons-material';

import { CallCountData } from './customtypes';
import { CallCountComponent } from './CallCountComponent';
import MiniChartWidget from './components/MiniChartWidget';
import DashboardWidgetProps from './types/DashboardWidget';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TotalUsersComponent: React.FunctionComponent<
  DashboardWidgetProps
> = (props) => {
  const { daysDiff, endDate } = props;
  const [open, setOpen] = React.useState(false);
  const [fetching, setFetching] = useState(false);
  const [totalUsersCount, setTotalUsersCount] = useState<CallCountData[]>([]);

  useEffect(() => {
    if (daysDiff) {
      // axios.get(`${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/dailyactivecount/${daysDiff}`,{timeout:120000})
      setFetching(true);
      fetch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/dailycount/${daysDiff}?end_dt=${endDate}`
      )
        .then((response) => response.json())
        .then((data) => {
          setTotalUsersCount(data);
          setFetching(false);
        })
        .catch((e) => console.log(e));
    }
  }, [daysDiff]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const usersCount = totalUsersCount.reduce(
    (accumulator, currentValue) => accumulator + currentValue.ct,
    0
  );
  const countByDate = totalUsersCount.map((d) => d.ct);

  return (
    <>
      <MiniChartWidget
        title="Total Number of Contacts"
        dataSet={countByDate}
        count={usersCount}
        loading={fetching}
        clickHandler={() => handleClickOpen()}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }} style={{ background: '#62a0a5' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Total Number of Contacts
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <CallCountComponent callCountData={totalUsersCount} />
      </Dialog>
    </>
  );
};
