import React, { createContext, useEffect, useState, useMemo } from 'react';
import { Hub, Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';

interface LoggedInContextInterface {
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  user: any;
  loading: boolean;
}

export const LoggedInContext = createContext<LoggedInContextInterface | null>(
  null
);

export const LoggedInProvider = ({
  children,
}: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<CognitoUser>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const authEvent = await authListener();

      return authEvent;
    })();
  }, []);

  async function authListener() {
    const hubEvent = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          setLoggedIn(true);
          break;
        case 'tokenRefresh':
          setLoggedIn(true);
          break;
        case 'signOut':
          setLoggedIn(false);
          break;
        default:
          setLoggedIn(false);
      }
    });

    try {
      const userResult = await Auth.currentAuthenticatedUser();
      setUser(userResult);
      setLoggedIn(true);
    } catch (err) {
      setLoggedIn(false);
    } finally {
      setLoading(false);
    }

    return hubEvent;
  }

  const value = useMemo(
    () => ({ loggedIn, setLoggedIn, user, loading }),
    [loggedIn, user, loading]
  );

  return (
    <LoggedInContext.Provider value={value}>
      {children}
    </LoggedInContext.Provider>
  );
};
