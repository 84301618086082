// import { GridColDef } from '@mui/x-data-grid';
import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Card, CircularProgress } from '@mui/material';
import * as Plot from '@observablehq/plot';

import DashboardWidgetProps from './types/DashboardWidget';

export interface InteractionRateData {
  date: string;
  avg_interaction: number;
}

export interface InteractionRateDataWithDate {
  date: Date;
  avg_interaction: number;
}

export const InteractionRateComponent: React.FunctionComponent<
  DashboardWidgetProps
> = ({ daysDiff, endDate, className }) => {
  const interactionratediv = useRef<HTMLDivElement>(null);

  const [fetching, setFetching] = useState(false);
  const [chartDimensions, setChartDimensions] = useState<{
    width: number;
  }>({
    width: 0,
  });
  const margin = 25;

  useEffect(() => {
    const updateDimensions = () => {
      if (interactionratediv.current) {
        setChartDimensions({
          width: interactionratediv.current.offsetWidth,
        });
      }
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const createSvg = (data: InteractionRateDataWithDate[]) => {
      const line = Plot.plot({
        width: chartDimensions.width - margin,
        marks: [Plot.line(data, { x: 'date', y: 'avg_interaction' })],
      });
      if (interactionratediv.current !== null) {
        d3.select('#interactionratediv').selectAll('svg')?.remove();
        interactionratediv.current.appendChild(line);
      }
    };

    if (daysDiff) {
      d3.select('#interactionratediv').selectAll('svg')?.remove();
      setFetching(true);
      const dateFunc = d3.utcParse('%Y-%m-%dT%H:%M:%S.%L');
      fetch(
        `${process.env.REACT_APP_DASHBOARD_API_URL}dashboard/interactionrate/${daysDiff}?end_dt=${endDate}`
      )
        .then((response) => response.json())
        .then((data) => {
          const dateWithDate = data.map((d: InteractionRateData) => ({
            date: dateFunc(d.date),
            avg_interaction: d.avg_interaction,
          }));
          setFetching(false);
          createSvg(dateWithDate);
        })
        .catch((e) => console.log(e));
    }
  }, [daysDiff]);
  return (
    <Card
      title="Interaction Rate"
      className={className && className}
      variant="outlined"
    >
      <div>
        <div style={{ display: 'grid', alignItems: 'normal' }}>
          <div>Interaction Rate</div>
        </div>
      </div>
      <div id="interactionratediv" ref={interactionratediv}>
        {fetching && (
          <div>
            <CircularProgress style={{ color: '#62a0a5' }} />
          </div>
        )}
      </div>
    </Card>
  );
};
