import React, { useState, useEffect } from 'react';
import { PickersShortcutsItem } from '@mui/x-date-pickers';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

import ResponsiveAppBar from '../ResponsiveAppBar';
import { TotalUsersComponent } from '../TotalUsersComponent';
import { TotalActiveUsersComponent } from '../TotalActiveUsersComponent';
import { TotalReturnedUsersComponent } from '../TotalReturnedUsersComponent';
import { TotalNewUsersComponent } from '../TotalNewUsersComponent';
import { ConversationDurationComponent } from '../ConversationDurationComponent';
import { FallbackIntentMetricComponent } from '../FallbackIntentMetricComponent';
import { CallTranscriptionsComponent } from '../CallTranscriptionsComponent';
import { ZeroOutRateComponent } from '../ZeroOutCallsComponent';
import { InteractionRateComponent } from '../InteractionRateComponent';
import './Dashboard.css';

const Dashboard: React.FC = () => {
  const initialDayjsState = dayjs() ?? null;

  const [val, setVal] = useState<DateRange<dayjs.Dayjs>>([
    initialDayjsState,
    initialDayjsState,
  ]);
  const [daysDiff, setDaysDiff] = useState<number>(0);
  const [endDate, setEndDate] = useState<string | undefined>('');

  const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: 'This Week',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Last Week',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Last 7 Days',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Current Month',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    {
      label: 'Next Month',
      getValue: () => {
        const today = dayjs();
        const startOfNextMonth = today.endOf('month').add(1, 'day');
        return [startOfNextMonth, startOfNextMonth.endOf('month')];
      },
    },
    { label: 'Reset', getValue: () => [null, null] },
  ];

  useEffect(() => {
    const daysDiffMillis = val[1]?.diff(val[0]);

    if (daysDiffMillis) {
      const daysDiff = Math.floor(daysDiffMillis / (1000 * 3600 * 24));
      setDaysDiff(daysDiff);
      setEndDate(val[1]?.format('YYYY-MM-DD'));
    }
  }, [val]);

  // const onDateSelect = (value: Date) =>{
  //   console.log(value)
  //   //setVal(value)
  //   console.log(val)
  //   const selectedDate   = Date.parse(val!) //Date.parse(value)
  //   const current = new Date()
  //   const daysDiff = Math.floor((current.getTime() - selectedDate) / (1000 * 3600 * 24));
  //   setDaysDiff(daysDiff)

  // }
  return (
    <div className="App">
      <ResponsiveAppBar />
      <div className="Dashboard__datepicker">
        <div>
          <DateRangePicker
            slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
            calendars={2}
            onChange={(e) => setVal(e!)}
          />
        </div>
        {endDate ? (
          <div>
            Showing Data for {daysDiff} days ending on {endDate}
          </div>
        ) : (
          <div>Select dates to view data</div>
        )}
      </div>
      <div className="Dashboard__charts">
        <div className="Dashboard__row">
          <TotalUsersComponent daysDiff={daysDiff} endDate={endDate} />
          <TotalActiveUsersComponent daysDiff={daysDiff} endDate={endDate} />
          <TotalReturnedUsersComponent daysDiff={daysDiff} endDate={endDate} />
          <TotalNewUsersComponent daysDiff={daysDiff} endDate={endDate} />
        </div>
        <div className="Dashboard__row">
          <ConversationDurationComponent
            className="Dashboard__card"
            daysDiff={daysDiff}
            endDate={endDate}
          />
          <FallbackIntentMetricComponent
            className="Dashboard__card"
            daysDiff={daysDiff}
            endDate={endDate}
          />
        </div>
        <div className="Dashboard__row">
          <ZeroOutRateComponent
            className="Dashboard__card"
            daysDiff={daysDiff}
            endDate={endDate}
          />
          <InteractionRateComponent
            className="Dashboard__card"
            daysDiff={daysDiff}
            endDate={endDate}
          />
        </div>
        <div>
          <CallTranscriptionsComponent daysDiff={daysDiff} endDate={endDate} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
